import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { httpGridConfig } from '@mca/shared/domain';
import { environmentToken } from '@mca/shared/util';
import { BehaviorSubject, Observable, map, shareReplay, skip, switchMap, take, tap, throwError } from 'rxjs';
import { ColumnsPreset, ColumnsStorageData, GridConfigStorageStrategy } from './types';

interface RemoteConfig extends Omit<ColumnsPreset, 'value'> {
  name: string;
  value: string;
}

@Injectable()
export class GridConfigStorageStrategyRemote implements GridConfigStorageStrategy {
  private httpClient = inject(HttpClient);
  private env = inject(environmentToken);

  private cachedConfig$ = new BehaviorSubject([] as RemoteConfig[]);
  // skips default value and replays last value for every subscriber
  private config$ = this.cachedConfig$.pipe(skip(1), shareReplay(1));

  constructor() {
    this.httpClient.get<RemoteConfig[]>(this.getUrl()).subscribe(this.cachedConfig$);
  }

  set(gridId: string, label: string, data?: Partial<ColumnsPreset>): Observable<boolean> {
    const value = data?.value && JSON.stringify(data.value);
    return this.get(gridId).pipe(
      switchMap(storageData => {
        // check if we have saved id for this label
        const presetId = String(storageData[label]?.id ?? '');
        const url = this.getUrl(presetId);
        const body = data && { name: gridId, label, preferred: data.preferred ?? false, value: value ?? '' };
        return this.makeRequest(url, presetId, body).pipe(map(() => true));
      }),
    );
  }

  get(gridId: string): Observable<ColumnsStorageData> {
    return this.config$.pipe(
      map(data => data.filter(v => v.name === gridId)), // find grid config
      // align data with interface
      map(presets => presets.reduce((acc, v) => Object.assign(acc, { [v.label ?? v.name]: { ...v, value: JSON.parse(v.value) } }), {})),
      take(1),
    );
  }

  private getUrl(presetId = '') {
    return this.env.apiUrl + httpGridConfig(presetId);
  }

  private makeRequest(url: string, presetId: string, body?: Partial<RemoteConfig>) {
    if (!body) {
      // we can throw/catch custom error in future to show nice messages
      return presetId ? this.httpClient.delete(url).pipe(tap(() => this.deleteFromCache(presetId))) : throwError(() => 'Nothing to delete');
    }
    if (!presetId) {
      return this.httpClient.post<RemoteConfig>(url, body).pipe(tap(() => this.insertInCache(body as RemoteConfig)));
    }
    return this.httpClient.put<RemoteConfig>(url, body).pipe(tap(({ id }) => this.updateInCache(id ?? 0, body as Partial<RemoteConfig>)));
  }

  private deleteFromCache(id: string) {
    const value = [...this.cachedConfig$.value];
    const index = value.findIndex(v => v.id === +id);
    if (index > -1) {
      value.splice(index, 1);
      this.cachedConfig$.next(value);
    }
  }

  private updateInCache(id: number, data: Partial<RemoteConfig>) {
    const value = [...this.cachedConfig$.value];
    const index = value.findIndex(v => v.id === +id);
    if (index > -1) {
      value[index] = { ...value[index], ...data };
      this.cachedConfig$.next(value);
    }
  }

  private insertInCache(data: RemoteConfig) {
    const value = [...this.cachedConfig$.value];
    value.push(data);
    this.cachedConfig$.next(value);
  }
}
