import { Directive, Input } from '@angular/core';
import { PrimeTemplate } from 'primeng/api';

@Directive({
  selector: '[libSharedGridHeadCell]',
  standalone: true,
})
export class GridHeadCellDirective extends PrimeTemplate {
  @Input('libSharedGridHeadCell') name = '';
}

@Directive({
  selector: '[libSharedGridHeadCellDefault]',
  standalone: true,
})
export class GridHeadCellDefaultDirective extends PrimeTemplate {}

@Directive({
  selector: '[libSharedGridBodyCell]',
  standalone: true,
})
export class GridBodyCellDirective extends PrimeTemplate {
  @Input('libSharedGridBodyCell') name = '';
}

@Directive({
  selector: '[libSharedGridBodyCellDefault]',
  standalone: true,
})
export class GridBodyCellDefaultDirective extends PrimeTemplate {}

@Directive({
  selector: '[libSharedGridFootCell]',
  standalone: true,
})
export class GridFootCellDirective extends PrimeTemplate {
  @Input('libSharedGridFootCell') name = '';
}

@Directive({
  selector: '[libSharedGridFootCellDefault]',
  standalone: true,
})
export class GridFootCellDefaultDirective extends PrimeTemplate {}

@Directive({
  selector: '[libSharedGridRowExpansion]',
  standalone: true,
})
export class GridRowExpansionDirective extends PrimeTemplate {}
