import { Injectable, inject } from '@angular/core';
import { DialogService } from '@mca/shared/feature-dialog';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, EMPTY, of } from 'rxjs';
import { catchError, filter, finalize, map, switchMap, tap } from 'rxjs/operators';

import { ColumnConfig, ColumnsPreset, ColumnsStorageData, defaultColumnsPreset, gridConfigStorageStrategyToken } from './types';
import { GridColumnsComponent } from './grid-columns.component';

@Injectable()
export class GridColumnService<T> {
  private dialogService = inject(DialogService);
  private messageService = inject(MessageService);
  private storageService = inject(gridConfigStorageStrategyToken);

  columns$ = new BehaviorSubject<ColumnConfig<T>>({} as ColumnConfig<T>);
  selectedPreset$ = new BehaviorSubject(defaultColumnsPreset);
  private storageId = 'grid';
  private storageData$ = new BehaviorSubject({
    default: { preferred: false, system: true, value: this.getDisplayColumns() },
  } as ColumnsStorageData);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  presets$ = this.storageData$.pipe(
    map(data => {
      const list = Object.keys(data);
      return list.length ? list : [defaultColumnsPreset];
    }),
  );

  updateColumns(config: ColumnConfig<T>) {
    const columns = { ...this.columns$.value };
    Object.keys(config).forEach(field => {
      columns[field] = { ...columns[field], ...config[field], field };
    });
    this.columns$.next(columns);
  }

  setColumns(config: ColumnConfig<T>) {
    const columns = Object.entries(config).reduce((acc, [field, data]) => Object.assign(acc, { [field]: { ...data, field } }), {});
    this.columns$.next(columns);
  }

  selectPreset(preset: string) {
    this.selectedPreset$.next(preset);
    const presetData = this.storageData$.value[preset];
    this.saveToStorage(preset, { ...presetData, preferred: true }).subscribe();
    const updateData = this.displayColumnsToUpdateData(presetData.value);
    this.updateColumns(updateData);
  }

  removePreset(name?: string) {
    const preset = name ?? this.selectedPreset$.value;
    this.saveToStorage(preset, undefined).subscribe();
    if (preset === defaultColumnsPreset) {
      return;
    }
    const storageData = { ...this.storageData$.value };
    delete storageData[preset];
    this.storageData$.next(storageData);
    this.selectedPreset$.next(defaultColumnsPreset);
    const defaultDisplayColumns = storageData[defaultColumnsPreset].value;
    this.updateColumns(this.displayColumnsToUpdateData(defaultDisplayColumns));
  }

  openColumnsConfig() {
    const displayColumnsSet = new Set(this.getDisplayColumns());
    this.dialogService
      .open({
        title: 'Configure columns',
        fields: [
          {
            name: 'preset',
            type: 'input',
            value: this.selectedPreset$.value,
            label: 'Preset',
          },
          {
            name: 'columns',
            type: 'component',
            component: GridColumnsComponent,
            componentInputs: {
              columnConfig: this.columns$.value,
              sourceFilters: Object.keys(this.columns$.value).filter(field => !displayColumnsSet.has(field)),
            },
            value: this.getDisplayColumns(),
          },
        ],
        styleClass: 'column-config-modal dialog__size-xl',
      })
      .pipe(
        filter(Boolean),
        switchMap(data => {
          this.selectedPreset$.next(data.preset);
          const updateData = this.displayColumnsToUpdateData(data.columns);
          this.updateColumns(updateData);
          return this.updateColumnsInStorage();
        }),
        finalize(() => this.dialogService.stopLoading()),
      )
      .subscribe(() => {
        this.dialogService.closeModal();
      });
  }

  /**
   * Column config should be able to work without storage.
   * Storage is considered enabled if storageId is set.
   */
  syncWithStorage(gridName: string) {
    this.storageId = 'grid##' + gridName;
    const defaultPreset = this.storageData$.value[defaultColumnsPreset];
    this.storageData$.next({ ...this.storageData$.value, [defaultColumnsPreset]: { ...defaultPreset, value: this.getDisplayColumns() } });
    this.loadFromStorage().subscribe(data => {
      this.storageData$.next({ ...this.storageData$.value, ...data });
      const preset = Object.keys(data).find(k => data[k].preferred) ?? this.selectedPreset$.value;
      const updateData = this.displayColumnsToUpdateData(data[preset]?.value ?? this.getDisplayColumns());
      this.updateColumns(updateData);
      this.selectedPreset$.next(preset);
    });
  }

  private getDisplayColumns(): string[] {
    return Object.keys(this.columns$.value)
      .filter(key => this.columns$.value[key].show ?? true)
      .sort((a, b) => (this.columns$.value[a]?.order ?? 0) - (this.columns$.value[b]?.order ?? 0));
  }

  private displayColumnsToUpdateData(dColumns: string[] | ColumnConfig<T>): ColumnConfig<T> {
    const allColumns = Object.keys(this.columns$.value);
    const columnNameSet = new Set(allColumns);
    const displayColumns = Array.isArray(dColumns)
      ? dColumns
      : Object.keys(dColumns)
          .filter(key => dColumns[key])
          .sort((a, b) => (dColumns[a]?.order ?? 0) - (dColumns[b]?.order ?? 0));
    const viewableColumns = displayColumns.filter(colName => columnNameSet.has(colName));
    const viewableColumnsSet = new Set(viewableColumns);
    return allColumns.reduce(
      (acc, colName) =>
        Object.assign(acc, {
          [colName]: { show: viewableColumnsSet.has(colName), order: displayColumns.indexOf(colName) },
        }),
      {},
    );
  }

  private updateColumnsInStorage() {
    if (!this.storageId) {
      return of(EMPTY);
    }
    const displayColumns = this.getDisplayColumns();
    const selectedPreset = this.selectedPreset$.value;
    return this.saveToStorage(selectedPreset, { preferred: true, value: displayColumns }).pipe(
      tap(() =>
        this.storageData$.next({
          ...this.storageData$.value,
          [selectedPreset]: { ...this.storageData$.value[selectedPreset], value: displayColumns },
        }),
      ),
    );
  }

  private loadFromStorage() {
    return this.storageService.get(this.storageId).pipe(
      catchError(e => {
        const message = typeof e === 'string' ? e : 'Cannot load grid config';
        this.messageService.add({ severity: 'warn', summary: message });
        return of({} as ColumnsStorageData);
      }),
    );
  }

  private saveToStorage(preset: string, data?: Partial<ColumnsPreset>) {
    return this.storageService.set(this.storageId, preset, data).pipe(
      catchError(e => {
        const message = typeof e === 'string' ? e : 'Cannot load grid config';
        this.messageService.add({ severity: 'warn', summary: message });
        return of(EMPTY);
      }),
    );
  }
}
