import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { GridConfigStorageStrategyRemote } from './grid-config-storage-strategy-remote';
import { GridComponent } from './grid.component';
import {
  GridBodyCellDefaultDirective,
  GridBodyCellDirective,
  GridFootCellDefaultDirective,
  GridFootCellDirective,
  GridHeadCellDefaultDirective,
  GridHeadCellDirective,
  GridRowExpansionDirective,
} from './template-directives';
import { gridConfigStorageStrategyToken } from './types';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';

@NgModule({
  exports: [
    GridComponent,
    GridHeadCellDirective,
    GridHeadCellDefaultDirective,
    GridBodyCellDirective,
    GridBodyCellDefaultDirective,
    GridFootCellDirective,
    GridFootCellDefaultDirective,
    GridRowExpansionDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    TableModule,
    TooltipModule,
    DropdownModule,
    InputTextModule,
    GridComponent,
    GridHeadCellDirective,
    GridHeadCellDefaultDirective,
    GridBodyCellDirective,
    GridBodyCellDefaultDirective,
    GridFootCellDirective,
    GridFootCellDefaultDirective,
    GridRowExpansionDirective,
  ],
  providers: [{ provide: gridConfigStorageStrategyToken, useClass: GridConfigStorageStrategyRemote }, CurrencyPipe, DatePipe, DecimalPipe],
})
export class SharedFeatureGridModule {}
