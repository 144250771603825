import { InjectionToken } from '@angular/core';
import { formatISO } from 'date-fns';
import { Observable } from 'rxjs';
import { ColumnFormat, GrigColumnFormatOptions } from './grid-column-format.service';

export interface ColumnParams<T> {
  field?: string;
  label?: string;
  show?: boolean;
  tooltip?: string;
  width?: string; // style value for col group, i.e. '100px'
  format?: ColumnFormat;
  exportFormat?: ColumnFormat;
  formatOptions?: GrigColumnFormatOptions;
  skipExport?: boolean;
  rowToggler?: boolean;
  sortable?: boolean;
  bodyClass?: string | ((row: T) => string);
  headClass?: string;
  order?: number;
  frozenColumn?: boolean;
  sort?: (a: T, b: T) => number;
  linkTarget?: string;
  value?: (row: T) => any;
  original?: (row: T) => any;
  originalMessage?: (row: T) => string;
  routerLink?: (row: T) => string | any[] | number | null;
  queryParams?: (row: T) => { [key: string]: string | number };
  total?: (value: number, length: number) => string;
}

export interface ColumnConfig<T> {
  [name: string]: ColumnParams<T>;
}

// returned observables should complete after the first emitted value
export interface GridConfigStorageStrategy {
  set: (gridId: string, label: string, data?: Partial<ColumnsPreset>) => Observable<boolean>;
  get: (gridId: string) => Observable<ColumnsStorageData>;
}

export const gridConfigStorageStrategyToken = new InjectionToken<GridConfigStorageStrategy>('gridConfigStorageStrategy');

export const defaultColumnsPreset = 'default';

export interface ColumnsPreset {
  id?: number;
  label?: string;
  preferred: boolean;
  system: boolean;
  value: string[];
}

export interface ColumnsStorageData {
  [label: string]: ColumnsPreset;
}

export interface GridPagination {
  page: number;
  pageSize: number;
  sortField?: string | string[] | null | undefined;
  sortOrder?: number | null | undefined;
}

export interface GridPaginationParams extends GridPagination {
  enabled: boolean;
  external: boolean;
  totalRecords: number;
}

export const defaultGridPagination: GridPaginationParams = {
  enabled: false,
  external: false,
  page: 0,
  pageSize: 50,
  totalRecords: 0,
};

export const paginationParamsToExternal = (params: GridPaginationParams): GridPagination => ({
  page: params.page,
  pageSize: params.pageSize,
  sortField: params.sortField,
  sortOrder: params.sortOrder,
});

export const filtersToParams = (filters: any, keepEmpty = false) =>
  Object.entries(filters).reduce(
    (acc, [key, value]) => {
      if (!value && !keepEmpty) {
        return acc;
      }
      let result = value;
      if (result instanceof Date) {
        result = formatISO(result, { representation: 'date' });
      }
      return Object.assign(acc, { [key]: result });
    },
    {} as { [k: string]: string | number | string[] | number[] },
  );

export interface CellClickEvent {
  row: any;
  rowIndex: number;
  column: ColumnParams<any>;
}
