import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { PickListModule } from 'primeng/picklist';
import { ColumnConfig } from './types';

@Component({
  selector: 'lib-shared-grid-columns',
  standalone: true,
  imports: [PickListModule],
  template: `
    <p-pickList
      [source]="sourceFilters"
      [target]="dynamicFormControl.value"
      sourceHeader="Available"
      targetHeader="Selected"
      [dragdrop]="true"
    >
      <ng-template let-fieldName pTemplate="item">
        <span>{{ columnConfig[fieldName].label }}</span>
      </ng-template>
    </p-pickList>
  `,
})
export class GridColumnsComponent {
  // external control from dialog
  @Input() dynamicFormControl!: AbstractControl<string[]>;
  @Input() sourceFilters = [] as string[];
  @Input() columnConfig!: ColumnConfig<any>;
}
