import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { Injectable, inject } from '@angular/core';

export type ColumnFormat = 'currency' | 'number' | 'decimal' | 'percent' | 'date' | 'datetime' | 'datetimezone' | 'boolean' | 'none';

export interface GrigColumnFormatOptions {
  digitsInfo?: string;
  timezone?: string;
}

@Injectable({
  providedIn: 'root',
})
export class GridColumnFormatService {
  private datePipe = inject(DatePipe);
  private currencyPipe = inject(CurrencyPipe);
  private decimalPipe = inject(DecimalPipe);

  format(
    value: number | string | Date,
    type: ColumnFormat,
    formatOptions = {
      digitsInfo: undefined,
      timezone: '0',
    } as GrigColumnFormatOptions,
  ): string {
    if (value === null || value === undefined) {
      return '';
    }
    switch (type) {
      case 'currency':
        return this.currencyPipe.transform(value as number | string, undefined, undefined, formatOptions.digitsInfo) as string;
      case 'number':
        return this.decimalPipe.transform(value as number | string, '1.0-0') as string;
      case 'decimal':
        return this.decimalPipe.transform(value as number | string, formatOptions.digitsInfo ?? '1.0-3') as string;
      case 'percent':
        return `${this.decimalPipe.transform(value as number | string, formatOptions.digitsInfo ?? '1.0-3')}%`;
      case 'date':
        return this.datePipe.transform(value, 'MM/dd/yyyy', this.isISOString(value) ? undefined : formatOptions.timezone) as string;
      case 'datetime':
        return this.datePipe.transform(value, 'MM/dd/yyyy H:mm:ss', this.isISOString(value) ? undefined : formatOptions.timezone) as string;
      case 'datetimezone':
        return this.datePipe.transform(value, 'MM/dd/yyyy H:mm:ss z') as string;
      case 'boolean':
        return value ? 'Yes' : 'No';
      default:
        return String(value);
    }
  }

  private isISOString(value: any) {
    return typeof value === 'string' && /^\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])$/.test(value);
  }
}
